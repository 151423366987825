import { BASE_URL } from "../constants/BaseUrl";

const Logout = async () => {
  try {
    const response = await fetch(`${BASE_URL}/LogoutUser`, {
      method: "POST",
      credentials: "include",
    });

    if (response.ok) {
      sessionStorage.clear();
      return true;
    } else {
      throw new Error("Logout failed");
    }
  } catch (error) {
    console.error("Error during Logout:", error);
    throw error;
  }
};

export default Logout;
