import { BASE_URL } from "../constants/BaseUrl";

export const DossierUpdate = async (dossierId,updates, userid, usertype) => {
  try {
    const response = await fetch(`${BASE_URL}/DossierUpdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dossierId,...updates, userid, usertype }),
    });

    if (response.ok) {
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    console.error("Failed to update dossier:", error);
    throw error;
  }
};
export const TriggerPdfGeneration = async (dossier_id,garage_id) => {
  try {
    const response = await fetch(`${BASE_URL}/generate-pdf/trigger-generation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dossier_id , garage_id}),
    });

    if (response.ok) {
      const updatedDossier =  await response.json();
      return updatedDossier;
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    console.error("Failed to view pdf:", error);
    throw error;
  }
};

