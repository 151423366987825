import { BASE_URL } from "../constants/BaseUrl";

export const UpdateFolder = async (dossier_id, formData) => {
  try {
    const response = await fetch(`${BASE_URL}/UpdateFolder/${dossier_id}`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Update Folder failed");
    }
  } catch (error) {
    console.error("Error Updating Folder:", error);
    throw error;
  }
};
