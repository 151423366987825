import React, { useEffect, useState } from 'react'
import "../assets/ValueField.css";
import { IMAGE_URL } from '../constants/BaseUrl';
const ValueField = ({value,placeholder,label,isUrl}) => {
  const [stateValue] = useState(()=>{
    if (!isUrl) {
      return value;
    }
    if (value.split('|').length > 1) {
      return "";
    }
    let valClone = value.split('/');
    valClone.splice(1,2);
    return IMAGE_URL+"/"+valClone.join('/');
  });
  const [statesValue] = useState(()=>{
    if (!isUrl) {
      return [];
    }
    if (value.split('|').length <= 1) {
      return [];
    }
    let many = value.split('|');
    let arr = [];
    many.map((val)=>{
      let valClone = val.split('/');
      valClone.splice(1,2);
      arr.push(IMAGE_URL+"/"+valClone.join('/'));
    });
    return arr;
  });
  
  return (
    <div className='value-field-container'>
        <label className='value-field-label'>{label}</label>
        {
            isUrl && statesValue.length>1 ? (
              <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
                {
                  statesValue.map((val,index)=>{
                    if (index === statesValue.length-1) return <a href={val} target='_blank' rel="noopener noreferrer" key={index}>Lien {index+1} de {label}.</a>
                    return <a href={val} target='_blank' rel="noopener noreferrer" key={index}>Lien {index+1} de {label}.<span className='' >|</span></a>
                  })
                }
              </div>
            ) : 
            isUrl && stateValue ? <a href={stateValue} target='_blank' rel="noopener noreferrer">Lien de {label}</a> :
            value ? <p>{stateValue}</p> : <p>{placeholder}</p>
        }
    </div>
  )
}

export default ValueField