import { BASE_URL } from "../constants/BaseUrl";

  export const getUserData = async (User, userid) => {
    try {
      const response = await fetch(`${BASE_URL}/UserTable/${User}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userid })
      });
  
      if (response.ok) {
        const data = await response.json();
        return data; 
      } else {
        const errorMessage = await response.json();
        console.error(`Failed to retrieve ${User} data. Error message:`, errorMessage.message); 
        throw new Error(errorMessage.message);
      }
    } catch (error) {
      console.error(`Error retrieving ${User} data:`, error);
      throw error;
    }
  };
  
  export const blockUser = async (userId, isBlocked, userType) => {
    try {
      const response = await fetch(`${BASE_URL}/blockUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId, isBlocked, userType })
      });
  
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        const errorMessage = await response.json();
        console.error('Failed to block/unblock user. Error message:', errorMessage.message);
        throw new Error(errorMessage.message);
      }
    } catch (error) {
      console.error('Error blocking/unblocking user:', error);
      throw error;
    }
  };
  
  
  