import { BASE_URL } from "../constants/BaseUrl";

export const LoginUser = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/LoginUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      return response.json();
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUserDetails = async () => {
  try {
    const response = await fetch(`${BASE_URL}/UserDetails`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};
