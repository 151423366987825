import React, { useEffect } from 'react';
import {useDropzone} from 'react-dropzone';

function UploadDropzone({updateForm}) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: {
        'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
    onDropRejected: (fileRejections) => {
        fileRejections.forEach(({ file, errors }) => {
          errors.forEach((error) => {
            if (error.code === 'file-too-large') {
              console.error(`${file.name} is larger than 2 MB`);
            } else {
              console.error(error.message);
            }
          });
        });
    }
  });
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      updateForm(acceptedFiles[0]);
    }
  }, [acceptedFiles, updateForm]);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})} style={{
            border: "2px dashed #666",
            borderRadius: "10px",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
      }}>
        <input {...getInputProps()} />
        <p>Faites glisser des fichiers ici, ou cliquez pour sélectionner des fichiers.</p>
      </div>
      <aside>
        <h4>Fichiers acceptés</h4>
        <ul>
          {acceptedFiles.map(file => (
            <li key={file.path}>
              {file.path}
            </li>
          ))}
        </ul>
        </aside>
    </section>
  );
}

export default UploadDropzone;