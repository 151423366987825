import { BASE_URL } from "../constants/BaseUrl";

export const GetGarageById = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/MyProfile/garage/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Retrieve Garage failed");
    }
  } catch (error) {
    console.error("Error Retrieving Garage:", error);
    throw error;
  }
};
export const UpdateGarageById = async (id,formData) => {
  try {
    const response = await fetch(`${BASE_URL}/UpdateUserDetails`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Update Garage failed");
    }
  } catch (error) {
    console.error("Error Updating Garage:", error);
    throw error;
  }
};
